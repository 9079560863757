import React, {useContext, createContext, useState, useEffect, useCallback, useMemo} from 'react';
import {useHistory, matchPath, RouteProps} from 'react-router-dom';
import Loader from '../../affordance/Loader';
import useLocalStorage from '../../util/useLocalStorage';
import useStateMachine from '../../util/useStateMachine';
import {OAuthPost, OAuthNavigate, ParseToken} from './OAuth';
import useAuthorizedFetch from './useAuthorizedFetch';
import useAuthorizedWebsocket from './useAuthorizedWebsocket';

export type TokenViewer = {
    id: string;
    teamIds: string[];
    // This isnt a complete user, there is more in the token,
    // but we prefer to use the `userViewer` context. This only really exists
    // for internal apps that use a tiny bit of user information
    user: {
        username: string;
    };
};

type Auth = {
    authorizedFetch: ReturnType<typeof useAuthorizedFetch>;
    changeTeam: (teamId: string) => void;
    initialLogin: boolean;
    clearInitialLogin: () => void;
    teamId: string | null;
    isPublic: boolean;
    tokenViewer: TokenViewer;
    accessToken: string | null;
    getNewAccessToken: () => void;
};

export const AuthContext = createContext<Auth | undefined>(undefined);
export function useAuth(): Auth {
    const authContext = useContext(AuthContext);
    if (!authContext) throw new Error('AuthContext used before provider');
    return authContext;
}

export default function AuthProvider(props: {
    children: any;
    publicRoutes?: Array<string | RouteProps>;
    pdfRoutes?: Array<string | RouteProps>;
}) {
    const OAUTH_CALLBACK = '/oauth/callback';
    const {publicRoutes = [], pdfRoutes = [], children} = props;
    const {location, replace, listen} = useHistory<{from?: string; dontTrack?: boolean}>();
    const redirectUrl = location.state?.from || location.pathname + location.search;
    const searchParams = new URLSearchParams(location.search);

    const [accessToken, setAccessToken] = useLocalStorage('bd-auth-accessToken');
    const [refreshToken, setRefreshToken] = useLocalStorage('bd-auth-refreshToken');
    const [teamId, setTeamId] = useLocalStorage('bd-auth-currentTeam');

    const [, setError] = useState();
    const [initialLogin, setInitialLogin] = useState(false);
    const [tokenExpiry, setTokenExpiry] = useState(() => {
        // accessTokens from local storage can get malformed in some edge cases
        // it's nice to have the tokenExpiry up front, but if the parsing fails
        // the whole app breaks. Better to try and fail silently here.
        if (accessToken) {
            try {
                return ParseToken(accessToken).tokenExpiry;
            } catch (e) {
                return null;
            }
        }
        return null;
    });
    const [tokenViewer, setTokenViewer] = useState<TokenViewer>({
        id: '',
        teamIds: [],
        user: {username: ''}
    });

    const {state, go} = useStateMachine('empty', {
        empty: async (go) => {
            const {pathname} = location;
            if (publicRoutes.some((route) => matchPath(pathname, route)) && !accessToken)
                return go('public');
            if (pdfRoutes.some((route) => matchPath(pathname, route))) return go('pdf');
            if (pathname === OAUTH_CALLBACK) return go('accessTokenRequest');
            if (pathname === '/logout') return go('logout');
            if (pathname === '/forgotPassword') return go('forgotPassword');
            if (pathname === '/login' || !accessToken) return go('login');
            return go('refreshTokenRequest');
        },
        login: async () => OAuthNavigate('login', {redirectUrl}),
        forgotPassword: async () => OAuthNavigate('forgotPassword', {redirectUrl: '/'}),
        logout: async (_, redirectUrl = '/') => {
            setAccessToken(null);
            setRefreshToken(null);
            OAuthNavigate('logout', {redirectUrl});
        },
        accessTokenRequest: async (go) => {
            const {accessToken, refreshToken} = await OAuthPost(
                'accessToken',
                searchParams.get('code')
            );
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
            go('tokenResolve', {accessToken});
        },
        refreshTokenRequest: async (go) => {
            const {accessToken, refreshToken: nextRefreshToken} = await OAuthPost(
                'refreshToken',
                refreshToken
            );
            setAccessToken(accessToken);
            setRefreshToken(nextRefreshToken);
            go('tokenResolve');
        },
        tokenResolve: async () => {
            const {availableTeamIds, tokenExpiry, viewerData} = ParseToken(accessToken);
            setTokenExpiry(tokenExpiry);
            setTokenViewer(viewerData);
            const fromQuery = availableTeamIds.find((id) => id === searchParams.get('currentTeam'));
            const fromLocalStorage = availableTeamIds.find((id) => id === teamId);
            const nextTeamId = fromQuery || fromLocalStorage || availableTeamIds[0];
            if (nextTeamId !== teamId) setTeamId(nextTeamId);
            const stateParam = searchParams.get('state');

            if (location.pathname === OAUTH_CALLBACK) {
                if (stateParam) {
                    try {
                        const {redirectUrl, initialLogin} = JSON.parse(stateParam);
                        replace(redirectUrl || '/');
                        setInitialLogin(initialLogin);
                    } catch (e) {
                        // Something went bad with json parse
                    }
                } else {
                    replace('/');
                }
            }
            return go('ready');
        },
        public: async () => {},
        pdf: async () => {
            // The pdf part of the app is either for the api or development
            // so we try to find a token from url, or from storage, or just fail
            // no need to log the user out. The api would prefer a dead screen.
            const urlToken = searchParams.get('accessToken') || accessToken;
            if (urlToken) {
                setAccessToken(urlToken);
                go('tokenResolve');
            } else {
                go('error', new Error('Could not find a valid token to render report with'));
            }
        },
        ready: async () => {},
        error: async (go, error) => {
            if (error.name === 'InvalidTokenError') return go('logout');
            if (error.name === 'OAUTH_INVALID_GRANT_REFRESH_TOKEN_EXPIRED') return go('logout');
            if (error.name === 'OAUTH_INVALID_GRANT_RECORD_NOT_FOUND') return go('logout');
            if (error.name === 'OAUTH_INVALID_GRANT_CODE_EXPIRED') return go('logout');
            if (location.pathname === OAUTH_CALLBACK) replace('/');
            setError(() => {
                throw error;
            });
        }
    });

    /* Follow history changes to bind important state changes. Not sure if this is a good idea.
    It kind of breaks the react/state machine idea. Might be better to have routes above
    everything that create the auth provider with an initial state */
    useEffect(() => {
        return listen(({pathname}) => {
            if (state === 'public') return go('empty');
            if (pathname.match(/^\/pdf/)) return go('pdf');
            if (pathname === '/logout') return go('logout');
            if (pathname === '/login') return go('login');
            if (pathname === '/forgotPassword') return go('forgotPassword');
        });
    }, [state]);

    const authorizedFetch = useAuthorizedFetch({
        accessToken,
        refreshToken,
        teamId,
        tokenExpiry,
        redirectUrl,
        onUpdateTokens: ({accessToken, refreshToken}) => {
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
        },
        onLogout: (redirectUrl) => go('logout', redirectUrl)
    });

    const authorizedWebsocket = useAuthorizedWebsocket({
        accessToken,
        refreshToken,
        onUpdateTokens: ({accessToken, refreshToken}) => {
            setAccessToken(accessToken);
            setRefreshToken(refreshToken);
        },
        onLogout: (redirectUrl) => go('logout', redirectUrl)
    });

    const changeTeam = useCallback((nextTeamId: string) => {
        setTeamId(nextTeamId);
        replace('/', {dontTrack: true});
    }, []);

    const isPublic = state === 'public';

    const auth = useMemo(
        () => ({
            teamId,
            changeTeam,
            initialLogin,
            clearInitialLogin: () => setInitialLogin(false),
            authorizedFetch,
            authorizedWebsocket,
            isPublic,
            tokenViewer,
            accessToken,
            getNewAccessToken: () => go('refreshTokenRequest')
        }),
        [
            authorizedFetch,
            authorizedWebsocket,
            initialLogin,
            changeTeam,
            teamId,
            isPublic,
            tokenViewer
        ]
    );

    switch (state) {
        case 'ready':
        case 'public':
        case 'pdf':
            return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;

        default:
            return <Loader />;
    }
}
